// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDD45H1mZzRndoCgmOrLkV1m4lcLrguR3Q",
  authDomain: "ataparaconselho-f9c42.firebaseapp.com",
  projectId: "ataparaconselho-f9c42",
  storageBucket: "ataparaconselho-f9c42.appspot.com",
  messagingSenderId: "1013915105221",
  appId: "1:1013915105221:web:10f6ec180151f229a83dac",
  measurementId: "G-SLKBJRKB33"
};


initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();
export const storage = getStorage();
export const database = getDatabase();