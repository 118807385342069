import React from 'react';
import {
    Container,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Paper,
    Box,
    AppBar,
    Button
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();

const HistoricoPublico = ({ historicoReunioes, teste1 }) => {
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" sx={{ mt: 4 }}>
                <AppBar position="fixed" color="transparent" elevation={0} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', mb: 4, backgroundColor: '#FFFFFF' }}>
                    <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <img src='https://images-us-prod.cms.commerce.dynamics.com/cms/api/fswvqbgntk/imageFileData/MA2bhk?ver=9757' style={{ width: 30 }} />
                            <Typography variant="h5" color="primary" fontWeight="bold" textAlign={{ xs: 'center', sm: 'left' }} mb={{ xs: 2, sm: 0 }}>
                                Plano Detalhado Para Ações do Conselho
                            </Typography>
                        </div>
                        <div style={{ textAlign: 'center', display: 'flex', gap: 10 }}>
                            <Button variant="outlined" color="info" onClick={() => teste1()}>
                                Voltar
                            </Button>
                        </div>
                    </Container>
                </AppBar>



                <Typography variant="h4" color="primary" fontWeight="bold" textAlign="center" gutterBottom sx={{ mt: 6 }}>
                    Histórico de Reuniões
                </Typography>
                <Grid container spacing={4}>
                    {historicoReunioes.map((reuniao) => (
                        <Grid item xs={12} md={6} lg={4} key={reuniao.id}>
                            <Card elevation={3} sx={{ '&:hover': { boxShadow: 6 }, borderRadius: 3 }}>
                                <CardHeader
                                    title={reuniao.nome}
                                    subheader={`Data: ${reuniao.data} | Horário: ${reuniao.horario}`}
                                    sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0' }}
                                />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        <strong>Local:</strong> {reuniao.local}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        <strong>Áreas de Foco:</strong>
                                    </Typography>
                                    <ul>
                                        {Object.keys(reuniao.metas).map((category) => (
                                            reuniao.metas[category].hoje || reuniao.metas[category].meta ? (
                                                <li key={category}>
                                                    <strong>{category}:</strong> Hoje: {reuniao.metas[category].hoje}, Meta: {reuniao.metas[category].meta}
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        <strong>1 - Edificação:</strong> {reuniao.edificacao}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        <strong>2 - Administração:</strong> {reuniao.administracao}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        <strong>3 - Ministração:</strong>
                                    </Typography>
                                    <ul>
                                        {reuniao.ministracao.map((item, index) => (
                                            <li key={index}>
                                                <strong>Nome:</strong> {item.nome}, <strong>Próx. Ordenança:</strong> {item.proxOrdenanca}, <strong>Indicador:</strong> {item.indicador}, <strong>Responsável:</strong> {item.responsavel}, <strong>Quando:</strong> {item.quando}
                                            </li>
                                        ))}
                                    </ul>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        <strong>4 - Avaliação:</strong> {reuniao.avaliacao}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default HistoricoPublico;
