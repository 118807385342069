import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/index'; 
import PlanoDetalhado from './PlanoDetalhado'; 
import './index.css';
import LoginScreen from './pages/auth';

import { Container, Typography, CircularProgress } from '@mui/material';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Verifica se o usuário está autenticado
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

  
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <Container
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Carregando, por favor aguarde...
        </Typography>
        <CircularProgress />
      </Container>
    );
  }
  

  return (
    <React.StrictMode>
      {user ? <PlanoDetalhado /> : <LoginScreen />} {/* Renderiza a tela apropriada */}
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
