import React, { useState } from 'react';
import {
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { LockOutlined, PersonOutline } from '@mui/icons-material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../config/index'; // Importa a configuração do Firebase

export default function LoginScreen() {
  const [username, setUsername] = useState('ataparaconselho@gmail.com');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    if (username && password) {
      signInWithEmailAndPassword(auth, username, password)
        .then(() => {
          console.log('Login realizado com sucesso!');
        })
        .catch((err) => {
          setError('Erro ao fazer login. Verifique suas credenciais.');
          console.error('Erro de login:', err);
        });
    } else {
      setError('Por favor, preencha todos os campos.');
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <img
        src="https://images-us-prod.cms.commerce.dynamics.com/cms/api/fswvqbgntk/imageFileData/MA2bhk?ver=9757"
        style={{ width: 50 }}
        alt="Logo"
      />
      <Typography variant="h5" textAlign="center" sx={{ fontWeight: '600' }}>
        Plano Detalhado Para Ações do Conselho
      </Typography>
      <Paper
        elevation={3}
        sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <form onSubmit={handleLogin} style={{ width: '100%', marginTop: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            // onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              startAdornment: <PersonOutline color="action" />,
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: <LockOutlined color="action" />,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Entrar
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
