import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, LockOutlined, PersonOutline } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HistoryIcon from '@mui/icons-material/History';
import LoginScreen from './pages/auth';
import HistoricoPublico from './pages/public_list';

import MenuIcon from '@mui/icons-material/Menu';

import LogoutIcon from '@mui/icons-material/Logout';

import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { auth, db } from './config/index';

const theme = createTheme();

const metasCategories = [
  { name: "FREQ. SACRAMENTAL", color: "#E0F7FA" },
  { name: "MINISTRAÇÃO", color: "#F0F4C3" },
  { name: "BATISMOS", color: "#F8BBD0" },
  { name: "MISSION. NO CAMPO", color: "#FFCDD2" },
  { name: "RECOM. TEMPLO ATIVA", color: "#FFE0B2" }
];



const PlanoDetalhado = () => {
  const [openHistorico, setOpenHistorico] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editReuniaoId, setEditReuniaoId] = useState(null);
  const handleOpenHistorico = () => setOpenHistorico(true);
  const handleCloseHistorico = () => setOpenHistorico(false);
  const [reuniaoInfo, setReuniaoInfo] = useState({ nome: '', local: 'Capela', data: '', horario: '' });
  const [metas, setMetas] = useState(
    metasCategories.reduce((acc, category) => ({ ...acc, [category.name]: { hoje: '', meta: '' } }), {})
  );
  const [edificacao, setEdificacao] = useState('');
  const [administracao, setAdministracao] = useState('');
  const [ministracao, setMinistracao] = useState([{ nome: '', proxOrdenanca: '', indicador: '', responsavel: '', quando: '' }]);
  const [avaliacao, setAvaliacao] = useState('');
  const [historicoReunioes, setHistoricoReunioes] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [teste, setTeste] = useState(false);

  useEffect(() => {
    fetchReunioes();
  }, []);

  const fetchReunioes = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'reunioes'));
      const reunioesData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setHistoricoReunioes(reunioesData);
    } catch (error) {
      console.error('Erro ao buscar reuniões:', error);
    }
  };

  const handleReuniaoChange = (field, value) => {
    setReuniaoInfo({ ...reuniaoInfo, [field]: value });
  };

  const handleMetaChange = (category, field, value) => {
    setMetas({ ...metas, [category]: { ...metas[category], [field]: value } });
  };

  const handleMinistracaoChange = (index, field, value) => {
    const updatedMinistracao = ministracao.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setMinistracao(updatedMinistracao);
  };

  const addMinistracaoRow = () => {
    setMinistracao([...ministracao, { nome: '', proxOrdenanca: '', indicador: '', responsavel: '', quando: '' }]);
  };

  const removeMinistracaoRow = (index) => {
    setMinistracao(ministracao.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    const novaReuniao = {
      ...reuniaoInfo,
      metas,
      edificacao,
      administracao,
      ministracao,
      avaliacao
    };

    try {
      if (editMode) {
        const reuniaoDoc = doc(db, 'reunioes', editReuniaoId);
        await updateDoc(reuniaoDoc, novaReuniao);
        setHistoricoReunioes((prevHistoricoReunioes) => prevHistoricoReunioes.map((reuniao) => (reuniao.id === editReuniaoId ? { ...novaReuniao, id: editReuniaoId } : reuniao)));
      } else {
        const docRef = await addDoc(collection(db, 'reunioes'), novaReuniao);
        setHistoricoReunioes([{ ...novaReuniao, id: docRef.id }, ...historicoReunioes]);
      }
      alert('Dados salvos com sucesso!');
      handleClear();
    } catch (error) {
      console.error('Erro ao salvar os dados:', error);
    }
  };

  const handleClear = () => {
    setReuniaoInfo({ nome: '', local: 'Capela', data: '', horario: '' });
    setMetas(metasCategories.reduce((acc, category) => ({ ...acc, [category.name]: { hoje: '', meta: '' } }), {}));
    setEdificacao('');
    setAdministracao('');
    setMinistracao([{ nome: '', proxOrdenanca: '', indicador: '', responsavel: '', quando: '' }]);
    setAvaliacao('');
    setEditMode(false);
    setEditReuniaoId(null);
  };

  const handleEditReuniao = (reuniao) => {
    setReuniaoInfo({ nome: reuniao.nome, local: reuniao.local, data: reuniao.data, horario: reuniao.horario });
    setMetas(reuniao.metas);
    setEdificacao(reuniao.edificacao);
    setAdministracao(reuniao.administracao);
    setMinistracao(reuniao.ministracao);
    setAvaliacao(reuniao.avaliacao);
    setEditMode(true);
    setEditReuniaoId(reuniao.id);
    handleCloseHistorico();
  };

  const handleDeleteReuniao = async (id) => {
    if (window.confirm("Tem certeza que deseja apagar esta reunião?")) {
      try {
        await deleteDoc(doc(db, 'reunioes', id));
        setHistoricoReunioes((prevHistoricoReunioes) => prevHistoricoReunioes.filter((reuniao) => reuniao.id !== id));
      } catch (error) {
        console.error('Erro ao apagar a reunião:', error);
      }
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  function teste1() {
    setTeste(!teste);
  }

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('Logout realizado com sucesso!');
      })
      .catch((error) => {
        console.error('Erro ao sair:', error);
      });
  };

  if (teste) {
    return <HistoricoPublico historicoReunioes={historicoReunioes} teste1={teste1} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <AppBar position="fixed" color="transparent" elevation={0} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', mb: 4, backgroundColor: '#FFFFFF' }}>
          <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <img src='https://images-us-prod.cms.commerce.dynamics.com/cms/api/fswvqbgntk/imageFileData/MA2bhk?ver=9757' style={{ width: 30 }} alt="Logo" />
              <Typography variant="h5" color="primary" fontWeight="bold"
                sx={{
                  '@media (max-width: 700px)': { display: 'none' }
                }}
              >
                Plano Detalhado Para Ações do Conselho
              </Typography>



              <Typography variant="h5" color="primary" fontWeight="bold"
                sx={{
                  '@media (min-width: 700px)': { display: 'none' }
                }}
              >
                Conselho
              </Typography>
            </div>

            {/* Ícone de Menu em telas menores que 700px */}
            <div style={{ display: "flex", gap: "10px" }}>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{
                  '@media (min-width: 700px)': { display: 'none' }
                }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>

              {/* Botões normais em telas maiores que 700px */}
              <Box sx={{
                '@media (max-width: 700px)': { display: 'none' },
                textAlign: 'center', display: 'flex', gap: '8px'
              }}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  {editMode ? 'Salvar Alterações' : 'Criar Plano'}
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleClear}>
                  Apagar
                </Button>
                <Button variant="outlined" color="info" startIcon={<HistoryIcon />} onClick={handleOpenHistorico}>
                  Histórico
                </Button>
                <Button variant="outlined" color="info" onClick={teste1}>
                  Registro
                </Button>
              </Box>

              <IconButton onClick={handleLogout} color="inherit">
                <LogoutIcon />
              </IconButton>
            </div>

            {/* Menu hambúrguer */}
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
              <List>
                <ListItem button onClick={handleSave}>
                  <ListItemText primary={editMode ? 'Salvar Alterações' : 'Criar Plano'} />
                </ListItem>
                <ListItem button onClick={handleClear}>
                  <ListItemText primary="Apagar" />
                </ListItem>
                <ListItem button onClick={handleOpenHistorico}>
                  <ListItemText primary="Histórico" />
                </ListItem>
                <ListItem button onClick={teste1}>
                  <ListItemText primary="Registro" />
                </ListItem>
              </List>
              <Divider />
            </Drawer>
          </Container>
        </AppBar>

        <Grid container spacing={4} sx={{ mt: 6 }}>
          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 2, backgroundColor: 'background.paper' }}>
              <Typography variant="h6" gutterBottom color="primary">
                Informações da Reunião
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nome da Reunião"
                    value={reuniaoInfo.nome}
                    onChange={(e) => handleReuniaoChange('nome', e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Local da Reunião</InputLabel>
                    <Select
                      value={reuniaoInfo.local}
                      onChange={(e) => handleReuniaoChange('local', e.target.value)}
                      label="Local da Reunião"
                    >
                      <MenuItem value="Capela">Capela</MenuItem>
                      <MenuItem value="Online">Online</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Data"
                    type="date"
                    value={reuniaoInfo.data}
                    onChange={(e) => handleReuniaoChange('data', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Horário"
                    type="time"
                    value={reuniaoInfo.horario}
                    onChange={(e) => handleReuniaoChange('horario', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 2, backgroundColor: 'background.paper' }}>
              <Typography variant="h6" gutterBottom color="primary">
                Áreas de Foco
              </Typography>
              <Grid container spacing={2}>
                {metasCategories.map((category) => (
                  <Grid item xs={12} sm={6} key={category.name}>
                    <Paper elevation={0} sx={{ p: 2, borderRadius: 2, backgroundColor: category.color, '&:hover': { boxShadow: 3 } }}>
                      <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                        {category.name}
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Hoje"
                        value={metas[category.name].hoje}
                        onChange={(e) => handleMetaChange(category.name, 'hoje', e.target.value)}
                        sx={{ mb: 1 }}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Meta"
                        value={metas[category.name].meta}
                        onChange={(e) => handleMetaChange(category.name, 'meta', e.target.value)}
                        variant="outlined"
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 2, backgroundColor: 'background.paper' }}>
              <Typography variant="h6" gutterBottom color="primary">
                1 - EDIFICAÇÃO
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Momento para fortalecer a liderança. Conduzido pelo(a) líder presidente. (5 a 10 minutos)"
                value={edificacao}
                onChange={(e) => setEdificacao(e.target.value)}
                variant="outlined"
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 2, backgroundColor: 'background.paper' }}>
              <Typography variant="h6" gutterBottom color="primary">
                2 - ADMINISTRAÇÃO
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Momento para tratar do calendário, comunicados, acompanhamento designações passadas, etc. Conduzido pelo secretário(a). (5 a 10 minutos)"
                value={administracao}
                onChange={(e) => setAdministracao(e.target.value)}
                variant="outlined"
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 2, backgroundColor: 'background.paper' }}>
              <Typography variant="h6" gutterBottom color="primary">
                3 - MINISTRAÇÃO
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nomes Indivíduos/Famílias</TableCell>
                      <TableCell>Próx. Ordenança</TableCell>
                      <TableCell>Indicador</TableCell>
                      <TableCell>Responsável</TableCell>
                      <TableCell>Quando</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ministracao.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <TextField
                            fullWidth
                            value={row.nome}
                            onChange={(e) => handleMinistracaoChange(index, 'nome', e.target.value)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Próx. Ordenança</InputLabel>
                            <Select
                              value={row.proxOrdenanca}
                              onChange={(e) => handleMinistracaoChange(index, 'proxOrdenanca', e.target.value)}
                              label="Próx. Ordenança"
                            >
                              <MenuItem value="Batismo">Batismo</MenuItem>
                              <MenuItem value="Sacramento">Sacramento</MenuItem>
                              <MenuItem value="Sac. Aarônico">Sac. Aarônico</MenuItem>
                              <MenuItem value="Bat./Confir. Templo">Bat./Confir. Templo</MenuItem>
                              <MenuItem value="Investidura">Investidura</MenuItem>
                              <MenuItem value="Selamento">Selamento</MenuItem>
                              <MenuItem value="Benção Patriarcal">Benção Patriarcal</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Indicador</InputLabel>
                            <Select
                              value={row.indicador}
                              onChange={(e) => handleMinistracaoChange(index, 'indicador', e.target.value)}
                              label="Indicador"
                            >
                              <MenuItem value="Freq. Sacramental">Freq. Sacramental</MenuItem>
                              <MenuItem value="Ministração">Ministração</MenuItem>
                              <MenuItem value="Batismo">Batismo</MenuItem>
                              <MenuItem value="Mission. no Campo">Mission. no Campo</MenuItem>
                              <MenuItem value="Recom. Templo Ativa">Recom. Templo Ativa</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            value={row.responsavel}
                            onChange={(e) => handleMinistracaoChange(index, 'responsavel', e.target.value)}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            type="date"
                            value={row.quando}
                            onChange={(e) => handleMinistracaoChange(index, 'quando', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => removeMinistracaoRow(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                startIcon={<AddIcon />}
                onClick={addMinistracaoRow}
                sx={{ mt: 2 }}
                variant="outlined"
              >
                Adicionar Linha
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 2, backgroundColor: 'background.paper' }}>
              <Typography variant="h6" gutterBottom color="primary">
                4 - AVALIAÇÃO
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="O propósito foi alcançado? O que aprendemos que podemos aplicar para melhorar nossos resultados? (5 minutos)"
                value={avaliacao}
                onChange={(e) => setAvaliacao(e.target.value)}
                variant="outlined"
              />
            </Paper>
          </Grid>
        </Grid>

        <Modal
          open={openHistorico}
          onClose={handleCloseHistorico}
          aria-labelledby="modal-historico-reunioes"
          aria-describedby="modal-historico-reunioes-descricao"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: '80%', md: '70%' },
              maxWidth: 1000,
              maxHeight: '80vh',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: { xs: 2, sm: 4 },
              borderRadius: 2,
              overflow: 'auto',
            }}
          >
            <Typography variant="h5" component="h2" gutterBottom color="primary" textAlign="center">
              Histórico de Reuniões
            </Typography>
            <Grid container spacing={3}>
              {historicoReunioes.map((reuniao) => (
                <Grid item xs={12} key={reuniao.id}>
                  <Card elevation={2} sx={{ '&:hover': { boxShadow: 6 } }}>
                    <CardHeader
                      title={reuniao.nome}
                      subheader={`Data: ${reuniao.data} | Horário: ${reuniao.horario}`}
                      action={
                        <div>
                          <IconButton color="primary" onClick={() => handleEditReuniao(reuniao)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton color="secondary" onClick={() => handleDeleteReuniao(reuniao.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      }
                    />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Local: {reuniao.local}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Ministração:</strong>
                      </Typography>
                      <ul>
                        {reuniao.ministracao.map((item, index) => (
                          <li key={index}>
                            <strong>Nome:</strong> {item.nome}, <strong>Próx. Ordenança:</strong> {item.proxOrdenanca}, <strong>Indicador:</strong> {item.indicador}, <strong>Responsável:</strong> {item.responsavel}, <strong>Quando:</strong> {item.quando}
                          </li>
                        ))}
                      </ul>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        <strong>Áreas de Foco:</strong>
                      </Typography>
                      <ul>
                        {metasCategories.map((category) => (
                          reuniao.metas[category.name].hoje || reuniao.metas[category.name].meta ? (
                            <li key={category.name}>
                              <strong>{category.name}:</strong> Hoje: {reuniao.metas[category.name].hoje}, Meta: {reuniao.metas[category.name].meta}
                            </li>
                          ) : null
                        ))}
                      </ul>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        <strong>1 - Edificação:</strong> {reuniao.edificacao}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        <strong>2 - Administração:</strong> {reuniao.administracao}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        <strong>4 - Avaliação:</strong> {reuniao.avaliacao}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Button variant="contained" onClick={handleCloseHistorico} sx={{ mt: 3, display: 'block', mx: 'auto' }}>
              Fechar
            </Button>
          </Box>
        </Modal>
      </Container>
    </ThemeProvider>
  );
};

export default PlanoDetalhado;
